<template>
  <div class="video-detail">
    <van-nav-bar :title="pageTitle" left-arrow @click-left="onClickLeft">
    </van-nav-bar>
    <div class="video-detail-subtitle">
      <span class="subtitle">视频信息</span>
      <span
        :class="{ active: model.onLine }"
        class="iconfont iconfont-shipin1"
        @click="showVideo(true)"
      ></span>
    </div>
    <div class="video-detail-table">
      <table>
        <tr>
          <td>摄像头</td>
          <td colspan="3" class="tal">{{ model.videoName || "-" }}</td>
        </tr>
        <tr>
          <td>安装位置</td>
          <td colspan="3" class="tal">
            {{ model.distributionControlArea || "-" }}
          </td>
        </tr>
        <tr>
          <td>拍摄像素(px)</td>
          <td>{{ model.picturePixel || "-" }}</td>
          <td>外观类型</td>
          <td>{{ model.videoAppearanceTypeName || "-" }}</td>
        </tr>
        <tr>
          <td>信号类型</td>
          <td>{{ model.signalTypes || "-" }}</td>
          <td>图像分辨率</td>
          <td>{{ model.resolutionRatio || "-" }}</td>
        </tr>
      </table>
    </div>
    <div class="video-detail-list">
      <van-cell title="所属部门" :value="model.departmentName" />
      <van-cell title="所属设施" :value="model.relatedDevice" />
      <van-cell title="关联传感器" :value="model.relatedSensor" />
      <van-cell title="所属危险源" :value="model.relatedDangerList" />
      <van-cell title="值班电话">
        <template #default>
          <a
            v-if="model.dutyPhone !== defaultValue"
            class="blue"
            :href="'tel:' + model.dutyPhone"
            >{{ model.dutyPhone }}</a
          >
          <span v-else>{{ model.dutyPhone || "-" }}</span>
        </template>
      </van-cell>
      <van-cell title="HSE负责人">
        <template #default>
          <a
            v-if="model.directorSafetyTel !== defaultValue"
            class="blue"
            :href="'tel:' + model.directorSafetyTel"
            >{{
              (model.directorSafety || "") + " " + model.directorSafetyTel
            }}</a
          >
          <span v-else>{{ model.directorSafetyTel || "-" }}</span>
        </template>
      </van-cell>
    </div>
    <VideoPopup v-model="isShowVideo" :model="model" />
  </div>
</template>

<script>
import { getVideoDetail } from "@/api/base";
import VideoPopup from "./components/videoPopup";
import { mapState } from "vuex";
export default {
  name: "VideoDetail",
  components: {
    VideoPopup
  },
  data() {
    return {
      defaultValue: "-",
      pageTitle: "详情",
      isShowVideo: false,
      model: {
        departmentName: "-",
        relatedDevice: "", // 所属设施
        relatedDangerList: "", // 所属危险源
        dutyPhone: "-", // 值班电话
        directorSafety: "-", // 负责人
        directorSafetyTel: "-", // 负责人电话
        onLine: false, // 是否在线
        videoName: "",
        distributionControlArea: "-", // 安装位置
        resolutionRatio: "-", // 图像分辨率
        picturePixel: "-", // 拍摄像素
        signalTypes: "-", // 信号类型
        videoAppearanceTypeName: "-", // 外观类型
        orgCode: "",
        videoIpAddr: "",
        nvrAddr: "",
        channelAddr: ""
      }
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo
    })
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    onClickLeft() {
      history.go(-1);
    },
    showVideo(v) {
      if (!this.model.onLine) {
        return;
      }
      this.model.orgCode = this.userInfo.orgCode;
      this.isShowVideo = v;
    },
    getDetail() {
      const { id } = this.$route.params;
      getVideoDetail(id).then(res => {
        this.pageTitle = res.videoName;
        for (let key in this.model) {
          this.model[key] = res[key];
          if (key === "relatedDevice" || key === "relatedDangerList") {
            this.model[key] = res[key] ? res[key].join(",") : this.defaultValue;
          }
        }
        this.model.relatedSensor =
          res?.sensors?.map(x => x.name)?.join() ?? `--`;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.video-detail {
  .video-detail-subtitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px 16px 8px 16px;
    align-items: center;
    .subtitle {
      color: #2e2e4d;
      font-size: 14px;
      font-weight: 400;
    }
    .iconfont {
      font-size: 24px;
      color: #d9dbe1;
      &.active {
        color: #1676ff;
      }
    }
  }
  .video-detail-table {
    margin: 0 16px;
    table {
      width: 100%;
    }
    td {
      border: 1px solid #eceef2;
      font-size: 13px;
      text-align: center;
      width: 20%;
      word-break: break-all;
      padding: 13px 5px;
      vertical-align: middle;
      &.tal {
        padding: 0 10px;
        text-align: left;
      }
      &.blue {
        color: #1676ff;
      }
      &.red {
        color: #f13030;
      }
      &:nth-child(2n + 1) {
        color: #8c8f97;
      }
    }
  }
  .video-detail-list {
    margin-top: 10px;
    .blue {
      color: #1676ff;
    }
    ::v-deep .van-cell__title {
      width: 100px;
      flex: none;
      margin-right: 12px;
    }
    .van-cell__value {
      text-align: right;
    }
  }
}
</style>
